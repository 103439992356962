import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdxadAlerts } from '../../../ui/modules/alerts/components/alerts/alerts.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['../../styles/form.scss']
})
export class PasswordResetComponent implements OnInit {
  form: UntypedFormGroup;
  code: string;
  public showPassword = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alerts: AdxadAlerts
  ) {}

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('code');
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    });
  }

  comparePasswords(): void {
    const confirmPassword = this.form.get('confirmPassword');

    if (this.form.get('password').value !== confirmPassword.value) {
      confirmPassword.setErrors({ passNotMatch: true });
    }
  }

  submit() {
    if (!this.code || !this.form.valid) {
      return;
    }
    const data = { password: this.form.value.password };

    this.authService
      .resetPassword(data, this.code)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result.status === 'OK') {
          this.alerts.success(result.message, 3000);
          this.router.navigate(['/auth']);
        }
      });
  }
}
