import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { QueryParamsService } from '../../../core/services/query-params.service';
import { AUTH_ROUTE } from '../../../core/routes';
import { distinctUntilChanged } from 'rxjs/operators';
import { GlobicaService } from '../../../core/services/globica.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../styles/form.scss']
})
export class LoginComponent implements OnInit {
  private fb = inject(FormBuilder);
  private needUri = '';
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private queryParamsService = inject(QueryParamsService);
  private authService = inject(AuthService);
  protected readonly AUTH_ROUTE = AUTH_ROUTE;

  globicaService = inject(GlobicaService);
  isLoading = false;
  showPassword = false;
  form!: FormGroup;

  ngOnInit(): void {
    this.createForm();
    /**
     * Get url for return after auth
     */
    const queryParams = this.queryParamsService.get();
    this.needUri = queryParams['return'] || '/';

    /**
     * Auto login after redirect from landing
     */
    if (Object.keys(queryParams).indexOf('username') !== -1 && Object.keys(queryParams).indexOf('password') !== -1) {
      this.form.controls.email.setValue(window.atob(queryParams['username']));
      this.form.controls.password.setValue(window.atob(queryParams['password']));
      this.queryParamsService.add(['username', 'password'], ['', '']);
      this.submit();
    }

    this.form.statusChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe(status => {
      if (status === 'VALID') {
        this.globicaService.trackEventGoals('buttonLogin_enable');
      }
    });
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      grant_type: 'urn:saas-password',
      scope: 'adxad'
    });

    this.globicaService.trackFormControlsStatus(this.form, 'formLogin', 'invalid');
  }

  /**
   * Authenticate request
   * If is success, get user info request
   */
  submit(): void {
    this.globicaService.trackEventGoals('buttonLogin_click');

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.authService
      .authenticate(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.globicaService.trackEventGoals('formLoginSuccess_confirm');
          this.router.navigateByUrl(this.needUri);
        },
        error: error => {
          this.isLoading = false;
          this.globicaService.trackEventGoals('alertLoginError_show', { error_type: error });
        }
      });
  }
}
