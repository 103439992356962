import { Routes } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout.component';
import { LoginComponent } from './components/login/login.component';
import { RegComponent } from './components/reg/reg.component';
import { PasswordResetRequestComponent } from './components/password-reset-request/password-reset-request.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { ConfirmEmailRequestComponent } from './components/confirm-email-request/confirm-email-request.component';
import { AUTH_ROUTE } from '../core/routes';

export const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: AUTH_ROUTE.auth
      },
      {
        path: AUTH_ROUTE.auth,
        component: LoginComponent,
        title: 'Login',
        data: {
          pageType: 'LOGIN'
        }
      },
      {
        path: AUTH_ROUTE.reg,
        component: RegComponent,
        title: 'Advertiser registration',
        data: {
          pageType: 'REGISTER',
          pageId: 'advertiser-registration'
        }
      },
      {
        path: AUTH_ROUTE.resetPassRequest,
        component: PasswordResetRequestComponent,
        title: 'Reset password request',
        data: {
          pageType: 'PASSWORD_RESTORE'
        }
      },
      {
        path: AUTH_ROUTE.resetPass,
        component: PasswordResetComponent,
        title: 'Please type your new password',
        data: {
          pageType: 'PASSWORD_CHANGE'
        }
      },
      {
        path: AUTH_ROUTE.confirmEmail,
        component: ConfirmEmailRequestComponent,
        title: 'Confirm email',
        data: {
          pageType: 'CONFIRM_EMAIL_REQUEST'
        }
      }
    ]
  }
];
