<adxad-loader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<form
  *ngIf="!isLoading"
  [formGroup]="form"
  class="auth-form"
  data-test="auth-form"
>
  <fieldset class="auth-form__fieldset">
    <legend
      class="auth-form__legend"
      data-test="auth-form-title"
    >
      {{ 'login' | transloco }}
    </legend>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        [class.error]="form.get('email').invalid && form.get('email').touched"
        placeholder="{{ 'email' | transloco }}"
        formControlName="email"
        type="text"
        data-test="auth-form-email-input"
      />
      <span
        [class.--is-valid]="form.get('email').valid"
        class="material-icons --prefix"
      >
        {{ form.get('email').valid ? 'check' : 'mail_outline' }}
      </span>
      <app-show-errors [control]="form.get('email')"></app-show-errors>
    </div>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        [class.error]="form.get('password').invalid && form.get('password').touched"
        placeholder="{{ 'password' | transloco }}"
        formControlName="password"
        [type]="showPassword ? 'text' : 'password'"
        data-test="auth-form-password-input"
      />
      <span
        (click)="showPassword = !showPassword"
        class="material-icons --prefix --password-visibility"
      >
        {{ !showPassword ? 'visibility_off' : 'visibility' }}
      </span>
      <app-show-errors [control]="form.get('password')"></app-show-errors>
    </div>
  </fieldset>

  <div class="auth-form__bar">
    <span> {{ 'forgotPassword' | transloco }} </span>
    <span
      (click)="globicaService.trackEventGoals('linkForgotPassword_click')"
      [routerLink]="'../' + AUTH_ROUTE.resetPassRequest"
      class="auth-form__link"
      data-test="auth-form-reset-link"
    >
      {{ 'resetPassword' | transloco }}
    </span>
  </div>

  <button
    (click)="submit()"
    [disabled]="isLoading || form.invalid"
    class="auth-form__btn"
    data-test="auth-form-submit-btn"
    type="submit"
  >
    {{ 'login' | transloco }}
  </button>

  <div class="auth-form__bar">
    <span> {{ 'dontHaveAnAccount' | transloco }} </span>
    <span
      (click)="globicaService.trackEventGoals('linkRegister_click')"
      [routerLink]="'../' + AUTH_ROUTE.reg"
      data-test="auth-form-registration-link"
      class="auth-form__link"
    >
      {{ 'registration' | transloco }}
    </span>
  </div>
</form>
