import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { AdxadAlerts } from '../../../ui/modules/alerts/components/alerts/alerts.component';
import { AUTH_ROUTE } from '../../../core/routes';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobicaService } from '../../../core/services/globica.service';
import { TranslocoService } from '@jsverse/transloco';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['../../styles/form.scss']
})
export class RegComponent implements OnInit {
  readonly AUTH_ROUTE = AUTH_ROUTE;
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private alerts = inject(AdxadAlerts);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private gtmService = inject(GoogleTagManagerService);
  private destroyRef = inject(DestroyRef);

  public transloco = inject(TranslocoService);
  public globicaService = inject(GlobicaService);
  public form: FormGroup;
  public isLoading = false;

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      code: [null, Validators.required],
      scope: 'adxad'
    });

    const referral = this.route.queryParams['value'].referral;

    if (referral) {
      this.form.addControl('referrerToken', this.fb.control(referral));
    }

    this.form.statusChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), debounceTime(300)).subscribe(status => {
      if (status === 'VALID') {
        this.globicaService.trackEventGoals('buttonRegister_enable');
      }
    });

    this.globicaService.trackFormControlsStatus(this.form, 'formRegister', 'invalid');
  }

  /**
   * Compare passwords in inputs
   */
  comparePasswords(): void {
    const confirmPassword = this.form.get('confirmPassword');

    if (this.form.get('password').value !== confirmPassword.value) {
      confirmPassword.setErrors({ passNotMatch: true });
    }
  }

  /**
   * Submit register request
   * If is success, auto-login
   */
  submit(): void {
    this.globicaService.trackEventGoals('buttonRegister_click');

    if (this.isLoading || !this.form.valid) {
      return;
    }

    this.isLoading = true;

    const request = this.form.value;
    delete request.confirmPassword;

    this.authService
      .register(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.isLoading = false;
          if (response.status === 'OK') {
            /**
             * Track for GTM
             */
            this.gtmService.pushTag({ event: 'onDspRegistration' });

            /**
             * Globica service
             */
            this.globicaService.trackEventGoals('user_registration');
            this.globicaService.trackAny('params', { user_member_id: response.id });
            this.alerts.success(this.transloco.translate('alert_registrationSuccessful'), 3000);
            this.authenticate();
          }
        },
        error: error => {
          this.globicaService.trackEventGoals('alertRegisterError_show', { error_type: error });
          this.alerts.error(error.error.message, 3000);
          this.isLoading = false;
        }
      });
  }

  /**
   * Authenticate request
   * If is success, get User info
   */
  authenticate(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const loginRequest = {
      email: this.form.value.email,
      password: this.form.value.password,
      grant_type: 'urn:saas-password',
      scope: 'adxad'
    };

    this.authService
      .authenticate(loginRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.globicaService.trackEventGoals('formRegisterContacts_show');
          this.router.navigateByUrl('/');
        },
        error: () => (this.isLoading = false)
      });
  }
}
