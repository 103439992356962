@if (isLoading) {
<adxad-loader [diameter]="40" />
} @else {
<form
  *transloco="let t;"
  [formGroup]="form"
  class="auth-form"
>
  <fieldset class="auth-form__fieldset">
    <legend class="auth-form__legend">{{ t('registration') }}</legend>

    <p class="auth-form__legend-description">{{ t('pleaseEnterValidEmailYouNeedToAccess') }}</p>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        placeholder="{{ t('email') }}"
        formControlName="email"
        [class.error]="form.get('email').invalid && form.get('email').touched"
        type="text"
      />
      <span
        [class.--is-valid]="form.get('email').valid"
        class="material-icons --prefix"
      >
        {{ form.get('email').valid ? 'check' : 'mail_outline' }}
      </span>
      <app-show-errors [control]="form.get('email')" />
    </div>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        placeholder="{{ t('password') }}"
        formControlName="password"
        [class.error]="form.get('password').invalid && form.get('password').touched"
        type="password"
      />
      <span
        [class.--is-valid]="form.get('password').valid"
        class="material-icons-outlined --prefix"
      >
        {{ form.get('password').valid ? 'check' : 'lock' }}
      </span>
      <app-show-errors [control]="form.get('password')" />
    </div>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        placeholder="{{ t('repeatPassword') }}"
        formControlName="confirmPassword"
        type="password"
        [class.error]="form.get('confirmPassword').invalid && form.get('confirmPassword').touched"
        (input)="comparePasswords()"
        (blur)="comparePasswords()"
      />
      <span
        [class.--is-valid]="form.get('confirmPassword').valid"
        class="material-icons-outlined --prefix"
      >
        {{ form.get('confirmPassword').valid ? 'check' : 'lock' }}
      </span>
      <app-show-errors [control]="form.get('confirmPassword')" />
    </div>

    <div class="form__field">
      <re-captcha formControlName="code" />
    </div>
  </fieldset>

  <div class="terms">
    <p [innerHTML]="t('registerTerms', {locale: '' + transloco.getActiveLang() + ''})"></p>
    <p [innerHTML]="t('alsoPleaseCheckOurGuidelinesForAdvertisers', {locale: '' + transloco.getActiveLang() + ''})"></p>
  </div>

  <button
    (click)="submit()"
    [disabled]="form.invalid"
    class="auth-form__btn"
    type="submit"
  >
    {{ t('registration') }}
  </button>

  <div class="auth-form__bar">
    <span> {{ t('alreadyHaveAnAccount') }} </span>
    <span
      (click)="globicaService.trackEventGoals('linkLogin_click')"
      [routerLink]="'../' + AUTH_ROUTE.auth"
      class="auth-form__link"
    >
      {{ t('login') }}
    </span>
  </div>
</form>
}
