<adxad-loader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<form
  class="auth-form"
  [formGroup]="form"
  *ngIf="!isLoading && !isSuccess"
>
  <fieldset class="auth-form__fieldset">
    <legend class="auth-form__legend">{{ 'resetPassword' | transloco }}</legend>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        placeholder="{{ 'email' | transloco }}"
        formControlName="email"
        [class.error]="form.get('email').invalid && form.get('email').touched"
        type="text"
      />
      <span
        [class.--is-valid]="form.get('email').valid"
        class="material-icons --prefix"
      >
        {{ form.get('email').valid ? 'check' : 'mail_outline' }}
      </span>
      <app-show-errors [control]="form.get('email')"></app-show-errors>
    </div>
  </fieldset>

  <p class="auth-form__text">{{ 'wellSendYouLetterWithResetLinkOnYourEmail' | transloco }}</p>

  <button
    (click)="submit()"
    [disabled]="form.invalid"
    class="auth-form__btn"
    type="submit"
  >
    <span class="material-icons">mail_outline</span>
    {{ 'sendMeALetter' | transloco }}
  </button>

  <div class="auth-form__bar">
    <span> {{ 'alreadyHaveAnAccount' | transloco }} </span>
    <span
      (click)="globicaService.trackEventGoals('linkLogin_click')"
      [routerLink]="'../' + AUTH_ROUTE.auth"
      class="auth-form__link"
    >
      {{ 'login' | transloco }}
    </span>
  </div>

  <div class="auth-form__bar">
    <span> {{ 'dontHaveAnAccount' | transloco }} </span>
    <span
      (click)="globicaService.trackEventGoals('linkRegister_click')"
      [routerLink]="'../' + AUTH_ROUTE.reg"
      class="auth-form__link"
    >
      {{ 'registration' | transloco }}
    </span>
  </div>
</form>

<p
  class="auth-form__text"
  *ngIf="isSuccess"
>
  {{ 'passwordResettingNotice' | transloco }}
</p>
