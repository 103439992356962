import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { AUTH_ROUTE } from '../../../core/routes';
import { distinctUntilChanged } from 'rxjs/operators';
import { GlobicaService } from '../../../core/services/globica.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['../../styles/form.scss', './password-reset-request.component.scss']
})
export class PasswordResetRequestComponent implements OnInit {
  readonly AUTH_ROUTE = AUTH_ROUTE;
  public form: UntypedFormGroup;
  public isSuccess = false;
  public isLoading = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    public globicaService: GlobicaService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      scope: 'adxad'
    });

    this.form.statusChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe(status => {
      if (status === 'VALID') {
        this.globicaService.trackEventGoals('buttonSendPassword_enable');
      }
    });
  }

  /**
   * Send reset password request
   */
  submit(): void {
    this.globicaService.trackEventGoals('buttonSendPassword_click');

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    const data = this.form.value;

    this.authService
      .resetPasswordRequest(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        result => {
          this.isLoading = false;

          if (result.status === 'OK') {
            this.globicaService.trackEventGoals('alertSendPasswordSuccess_show');
            this.isSuccess = true;
          }
        },
        error => {
          this.globicaService.trackEventGoals('alertSendPasswordError_show', { error_type: error });
          this.isLoading = false;
        }
      );
  }
}
