import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { AdxadAlerts } from '../../../ui/modules/alerts/components/alerts/alerts.component';
import { TranslocoService } from '@jsverse/transloco';
import { ProfileService } from '../../../core/services/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-confirm-email-request',
  template: `
    <div class="text-center">
      <adxad-loader [diameter]="40" />
    </div>
  `
})
export class ConfirmEmailRequestComponent implements OnInit {
  public isLoading = true;
  public error = '';
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private authService = inject(AuthService);
  private profileService = inject(ProfileService);
  private alerts = inject(AdxadAlerts);
  private transloco = inject(TranslocoService);

  ngOnInit(): void {
    const token = this.router.url.split('/').pop().split('?')[0];

    this.authService
      .confirmEmail(token)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ status, message }) => {
          if (status === 'OK') {
            this.alerts.success(message, 3000);
            this.profileService.loadProfile().then(() => {
              this.router.navigateByUrl('/');
            });
          } else {
            this.alerts.error(this.transloco.translate('alert_somethingWentWrong'), 3000);
          }
        },
        error: error => {
          this.error = error;
          this.router.navigateByUrl('/');
        }
      });
  }
}
