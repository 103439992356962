import { NgModule } from '@angular/core';
import { LoginComponent } from './components/login/login.component';
import { GuiModule } from '../gui/gui.module';
import { RegComponent } from './components/reg/reg.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './components/password-reset-request/password-reset-request.component';

import { AuthLayoutComponent } from './layout/auth-layout.component';
import { environment } from '../../environments/environment';
import { ConfirmEmailRequestComponent } from './components/confirm-email-request/confirm-email-request.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { RouterModule } from '@angular/router';
import { routes } from './auth.routes';
import { TranslocoModule } from '@jsverse/transloco';

const ENTRY_COMPONENTS = [
  LoginComponent,
  RegComponent,
  PasswordResetComponent,
  PasswordResetRequestComponent,
  AuthLayoutComponent,
  ConfirmEmailRequestComponent
];

@NgModule({
  imports: [GuiModule, TranslocoModule, RecaptchaModule, RecaptchaFormsModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [...ENTRY_COMPONENTS],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.sitekey
      } as RecaptchaSettings
    }
  ]
})
export class AuthModule {}
