<form
  class="auth-form"
  [formGroup]="form"
>
  <fieldset class="auth-form__fieldset">
    <legend class="auth-form__legend">{{ 'typeYourNewPassword' | transloco }}</legend>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        placeholder="{{ 'password' | transloco }}"
        formControlName="password"
        data-test="password-field"
        [class.error]="form.get('password').invalid && form.get('password').touched"
        [type]="showPassword ? 'text' : 'password'"
      />
      <span
        (click)="showPassword = !showPassword"
        class="material-icons --prefix --password-visibility"
      >
        {{ !showPassword ? 'visibility_off' : 'visibility' }}
      </span>
      <app-show-errors [control]="form.get('password')"></app-show-errors>
    </div>

    <div class="auth-form__field">
      <input
        class="auth-form__input"
        placeholder="{{ 'confirmPassword' | transloco }}"
        formControlName="confirmPassword"
        data-test="confirm-password-field"
        [class.error]="form.get('confirmPassword').invalid && form.get('confirmPassword').touched"
        [type]="showPassword ? 'text' : 'password'"
        (input)="comparePasswords()"
        (blur)="comparePasswords()"
      />
      <span
        (click)="showPassword = !showPassword"
        class="material-icons --prefix --password-visibility"
      >
        {{ !showPassword ? 'visibility_off' : 'visibility' }}
      </span>
      <app-show-errors [control]="form.get('confirmPassword')"></app-show-errors>
    </div>
  </fieldset>

  <button
    class="auth-form__btn"
    type="submit"
    data-test="submit-btn"
    [disabled]="form.invalid"
    (click)="submit()"
  >
    {{ 'resetPassword' | transloco }}
  </button>
</form>
