import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthData, Config, ConfirmEmailResponse, EntityResponse, ProfileViewResponse, RefreshToken } from '../core/interface';
import { environment } from '../../environments/environment';
import { ProfileService } from '../core/services/profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private config: Config = environment;

  private http = inject(HttpClient);
  private profileService = inject(ProfileService);

  /**
   * Register new user
   * @param data
   */
  public register(data): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.dsp_endpoint}/user/register`, data, { headers });
  }

  /**
   * Login
   * @param data
   */
  public authenticate(data: AuthData | RefreshToken): Observable<ProfileViewResponse> {
    return this.profileService.authenticate(data);
  }

  /**
   * Reset password request
   *
   * @param data
   */
  public resetPasswordRequest(data): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.dsp_endpoint}/password/resetting/request`, data, { headers });
  }

  /**
   * Reset password
   *
   * @param data
   * @param code
   */
  public resetPassword(data, code): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.dsp_endpoint}/password/resetting/reset/${code}`, data, {
      headers
    });
  }

  /**
   * Sent confirm email token
   * @param token
   */
  public confirmEmail(token): Observable<ConfirmEmailResponse> {
    return this.http.get<ConfirmEmailResponse>(`${this.config.dsp_endpoint}/user/confirmed/${token}`);
  }

  /**
   * Send receive confirm
   */
  public receiveConfirmEmail(): Observable<any> {
    return this.http.get(`${this.config.dsp_endpoint}/user/resend/verified_email`);
  }

  // constructor(
  //   private http: HttpClient,
  //   private profileService: ProfileService
  // ) {
  //
  // }
  //
  // /**
  //  * Register new user
  //  * @param data
  //  */
  // public register(data): Observable<EntityResponse> {
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.post<EntityResponse>(`${ this.config.dsp_endpoint }/user/register`, data, { headers });
  // }
  //
  // /**
  //  * Login
  //  * @param data
  //  */
  // public authenticate(data: AuthData | RefreshToken): Observable<ProfileViewResponse> {
  //   return this.profileService.authenticate(data);
  // }
  //
  // /**
  //  * Reset password request
  //  *
  //  * @param data
  //  */
  // public resetPasswordRequest(data): Observable<EntityResponse> {
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.post<EntityResponse>(`${ this.config.dsp_endpoint }/password/resetting/request`, data, { headers });
  // }
  //
  // /**
  //  * Reset password
  //  *
  //  * @param data
  //  * @param code
  //  */
  // public resetPassword(data, code): Observable<EntityResponse> {
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.post<EntityResponse>(`${ this.config.dsp_endpoint }/password/resetting/reset/${ code }`, data, {
  //     headers
  //   });
  // }
  //
  // /**
  //  * Sent confirm email token
  //  * @param token
  //  */
  // public confirmEmail(token): Observable<ConfirmEmailResponse> {
  //   return this.http.get<ConfirmEmailResponse>(`${ this.config.dsp_endpoint }/user/confirmed/${ token }`);
  // }
  //
  // /**
  //  * Send receive confirm
  //  */
  // public receiveConfirmEmail(): Observable<any> {
  //   return this.http.get(`${ this.config.dsp_endpoint }/user/resend/verified_email`);
  // }
}
