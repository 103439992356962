<div class="auth__left">
  <section class="intro">
    <div class="intro__logo">
      <img
        alt="ADxAD.com"
        src="/assets/images/adxad/logo.svg"
      />
    </div>

    <h2 class="intro__description">Get started now with our Full Service:</h2>

    <h1 class="intro__title">advertise to profit.</h1>

    <img
      alt="Start now with Free Full Service: advertise to profit."
      class="intro__img"
      src="/assets/images/adxad/intro_new.svg"
    />
  </section>
</div>

<div class="auth__right">
  <adxad-language-picker localesListPosition="right"></adxad-language-picker>
  <router-outlet></router-outlet>
</div>
